// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/vue-world-map";
import ReactiveSearch from "@appbaseio/reactivesearch-vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import "./registerServiceWorker";
import { VueMasonryPlugin } from "vue-masonry";
import { VueMaskDirective } from "v-mask";
import NProgress from "vue-nprogress";
import vueAwesomeCountdown from "vue-awesome-countdown";
import "./assets/css/custom.scss";
import VueAnalytics from "vue-analytics";

Vue.use(vueAwesomeCountdown, "vac");
Vue.use(NProgress);
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import VueRouterUserRoles from "vue-router-user-roles";

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

Vue.use(VueAnalytics, {
  id: "UA-232743136-2",
  router
});

import VueScrollactive from "vue-scrollactive";

Vue.use(VueScrollactive);

const nprogress = new NProgress();

Vue.use(VueRouterUserRoles, { router });
Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
Vue.use(ReactiveSearch);
Vue.use(VueMasonryPlugin);

const user = (localStorage.getItem("userRole") &&
  JSON.parse(localStorage.getItem("userRole"))) || { role: "guest" };
Vue.prototype.$user.set(user);

new Vue({
  nprogress,
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
