const state = () => ({
  gxGeneResults: []
});

const getters = {
  gxGeneResults: state => {
    return state.gxGeneResults;
  },
  gxGeneResultsDict: state => {
    const entries = new Map(
      state.gxGeneResults.map(e => {
        return [
          e.gene,
          {
            frequency: e.frequency,
            genotype: e.genotype,
            label: e.label,
            phenotype: e.phenotype,
            result: e.result
          }
        ];
      })
    );
    return Object.fromEntries(entries);
  },
  gxGeneResult: (state, getters) => geneSymbol => {
    return (
      getters.gxGeneResultsDict[geneSymbol] || {
        frequency: null,
        genotype: "NA",
        label: "gray",
        phenotype: "",
        result: "NA"
      }
    );
  }
};

const mutations = {
  UNSET_GX_GENE_RESULTS(state, gxGeneResults) {
    state.gxGeneResults = [];
  },
  SET_GX_GENE_RESULTS(state, gxGeneResults) {
    state.gxGeneResults = gxGeneResults;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
