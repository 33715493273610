import Api from "@/services/Strapi.js";

const state = () => ({
  drugsList: []
});

const getters = {
  getDrugs: state => {
    return state.drugsList;
  },
  drugsDict: state => {
    console.log(state.drugsList);
    const entries = new Map(
      state.drugsList.map(e => {
        return [e.slug, e];
      })
    );
    return Object.fromEntries(entries);
  },
  drugData: (state, getters) => slug => {
    return getters.drugsDict[slug];
  }
};

const mutations = {
  SET_DRUGS(state, drugs) {
    state.drugsList = drugs.map(d => {
      return {
        slug: d.slug,
        en: d.main_names.en,
        es: d.main_names.es,
        po: d.main_names.po
      };
    });
  }
};

const actions = {
  async getDrugs({ commit }) {
    commit("SET_DRUGS", []);
    const resp = await Api.get("/kb-drugs/all-without-relations");
    commit("SET_DRUGS", resp.data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
