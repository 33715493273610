import Api from "@/services/Strapi.js";

const state = () => ({
  faqs: []
});

const getters = {
  getFaqs: state => {
    return state.faqs.sort((a, b) => a.order - b.order);
  }
};
const actions = {
  async getFAQs({ commit }) {
    commit("SET_FAQS", []);
    return Api.get("/faqs")
      .then(resp => {
        commit("SET_FAQS", resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }
};

const mutations = {
  SET_FAQS(state, faqs) {
    state.faqs = faqs;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
