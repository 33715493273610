import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persist-indexeddb";
import auth from "./modules/auth";
import app from "./modules/app";
import pgxGenes from "./modules/pgx-genes";
import gxSample from "./modules/gx-sample";
import gxResults from "./modules/gx-results";
import gxGeneResults from "./modules/gx-gene-results";
import pgxDrugResults from "./modules/pgx-drug-results";
import drugCategories from "./modules/drug-categories";
import drugs from "./modules/drugs";
import faqs from "./modules/faqs";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: [
    "auth",
    "app",
    "pgxGenes",
    "gxSample",
    "gxResults",
    "gxGeneResults",
    "pgxDrugResults",
    "drugCategories",
    "drugs",
    "faqs"
  ]
});

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
    drawer: null
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    }
  },
  actions: {},
  modules: {
    auth,
    app,
    drugs,
    pgxGenes,
    gxSample,
    faqs,
    gxResults,
    gxGeneResults,
    pgxDrugResults,
    drugCategories
  },
  plugins: [dataState]
});

export default store;
