const state = () => ({
  gxResults: []
});

const getters = {
  gxResults: state => {
    return state.gxResults;
  },
  gxResultsDict: state => {
    const entries = new Map(
      state.gxResults.map(e => {
        return [e.key, e.value];
      })
    );
    return Object.fromEntries(entries);
  }
};

const mutations = {
  UNSET_GX_RESULTS(state, gxResults) {
    state.gxResults = [];
  },
  SET_GX_RESULTS(state, gxResults) {
    state.gxResults = gxResults;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
