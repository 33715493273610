export default {
  strict: false,
  namespaced: true,

  state() {
    return {
      helpDialogVisible: false,
      isDevEnvironment: false,
      allDataLoaded: false,
      siteURL: null
    };
  },

  getters: {
    isDevEnvironment: state => state.isDevEnvironment,
    apiSufix: state => (state.isDevEnvironment && "dev") || "v1",
    siteURL: state => state.siteURL,
    isHelpDialogVisible: state => state.helpDialogVisible,
    allDataLoaded: state => state.allDataLoaded
  },
  mutations: {
    SET_HELP_DIALOG_VISIBILITY(state, value) {
      state.helpDialogVisible = value;
    },
    SET_DEV_ENV(state, value) {
      state.isDevEnvironment = value;
    },
    SET_SITE_URL(state, value) {
      state.siteURL = value;
    },
    SET_ALL_DATA_LOADED(state, value) {
      state.allDataLoaded = value;
    }
  },
  actions: {
    changeHelpDialogVisibility({ commit }, visibility) {
      commit("SET_HELP_DIALOG_VISIBILITY", visibility);
    },
    setAllDataLoaded({ commit }, payload) {
      commit("SET_ALL_DATA_LOADED", payload);
    }
  }
};
