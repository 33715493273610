import Api from "@/services/Strapi.js";

const state = () => ({
  sampleUpdateDate: null,
  samplePatientName: null,
  sampleNumber: null
});

const getters = {
  sampleUpdateDate: state => {
    return state.sampleUpdateDate;
  },
  sampleNumber: state => {
    return state.sampleNumber;
  }
};
const actions = {
  async getMySampleData({ commit }) {
    commit("SET_SAMPLE", {});
    return Api.get("/sample-data")
      .then(resp => {
        commit("SET_SAMPLE", resp.data);
        commit("gxResults/SET_GX_RESULTS", resp.data.gxResults, { root: true });
        commit("gxGeneResults/SET_GX_GENE_RESULTS", resp.data.gxGeneResults, {
          root: true
        });
        commit(
          "pgxDrugResults/SET_PGX_DRUG_RESULTS",
          resp.data.pgxDrugResults,
          { root: true }
        );
      })
      .catch(err => {
        console.log(err);
      });
  },
  async getSampleData({ commit }, sampleId) {
    commit("SET_SAMPLE", {});
    return Api.get(`/sample-data/${sampleId}`)
      .then(resp => {
        commit("SET_SAMPLE", resp.data);
        commit("gxResults/SET_GX_RESULTS", resp.data.gxResults, { root: true });
        commit("gxGeneResults/SET_GX_GENE_RESULTS", resp.data.gxGeneResults, {
          root: true
        });
        commit(
          "pgxDrugResults/SET_PGX_DRUG_RESULTS",
          resp.data.pgxDrugResults,
          { root: true }
        );
      })
      .catch(err => {
        console.log(err);
      });
  }
};

const mutations = {
  UNSET_SAMPLE(state) {
    state.sampleUpdateDate = null;
    state.samplePatientName = null;
    state.sampleNumber = null;
  },
  SET_SAMPLE(state, payload) {
    state.sampleUpdateDate = payload.sampleUpdateDate;
    state.samplePatientName = payload.samplePatientName;
    state.sampleNumber = payload.sampleNumber;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
