import Vue from "vue";
import VueI18n from "vue-i18n";

import es from "vuetify/lib/locale/es";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    ...require("@/locales/en/texts.json"),
    ...require("@/locales/en/drugnames.json"),
    $vuetify: en
  },
  es: {
    ...require("@/locales/es/texts.json"),
    ...require("@/locales/es/drugnames.json"),
    $vuetify: es
  },
  po: {
    ...require("@/locales/po/texts.json"),
    ...require("@/locales/po/drugnames.json"),
    $vuetify: pt
  }
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "es",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es",
  messages
});
