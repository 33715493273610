import axios from "axios";
import NProgress from "nprogress";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    const user = localStorage.getItem("user");
    if (user) {
      const token = JSON.parse(user).idToken;
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();
    //const originalRequest = error.config;

    return Promise.reject(error);
  }
);

export default axios;
