import Api from "@/services/Strapi.js";

const state = () => ({
  pgxGenes: [],
  pgxGenesSubs: {}
});

const getters = {
  pgxGenes: state => {
    return state.pgxGenes;
  },
  pgxGenesByCategory: state => category => {
    return state.pgxGenes.filter(g => g.category === category);
  },
  pgxGenesDict: state => {
    const entries = new Map(
      state.pgxGenes.map(e => {
        return [e.symbol, e];
      })
    );
    return Object.fromEntries(entries);
  },
  pgxGene: (state, getters) => geneSymbol => {
    return getters.pgxGenesDict[geneSymbol] || {};
  },
  pgxGeneSubs: state => geneSymbol => {
    return state.pgxGenesSubs[geneSymbol] || [];
  },
  pgxGenesSortedSymbols: state => {
    return state.pgxGenes.map(g => g.symbol).sort();
  }
};
const actions = {
  async getPgxGenes({ commit }) {
    commit("SET_PGX_GENES", []);
    return Api.get("/pgx-genes")
      .then(resp => {
        commit("SET_PGX_GENES", resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  async getPgxGenesSubs({ commit }) {
    commit("SET_PGX_GENE_SUBS", []);
    return Api.get("/pgx-subs")
      .then(resp => {
        commit("SET_PGX_GENE_SUBS", resp.data.subs);
      })
      .catch(err => {
        console.log(err);
      });
  }
};

const mutations = {
  UNSET_PGX_DATA(state) {
    state.pgxGenes = [];
    state.pgxGenesSubs = {};
  },
  SET_PGX_GENES(state, pgxGenes) {
    state.pgxGenes = pgxGenes;
  },
  SET_PGX_GENE_SUBS(state, payload) {
    payload.forEach(drug => {
      const slug = drug.drug;
      drug.data_matrix_keys.forEach(dmk => {
        const gene = dmk[0].split("*")[0];
        if (!(gene in state.pgxGenesSubs)) {
          state.pgxGenesSubs[gene] = [];
        }
        state.pgxGenesSubs[gene].push(slug);
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
