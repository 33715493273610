import Api from "@/services/Strapi.js";

const state = () => ({
  drugCategories: []
});

const getters = {
  drugCategories: state => {
    return state.drugCategories;
  },
  drugCategoriesDict: state => {
    const entries = new Map(
      state.drugCategories.map(e => {
        return [
          e.index,
          {
            index: e.index,
            en: e.en,
            es: e.es,
            po: e.po,
            order: e.order,
            drugs: e.drugs
          }
        ];
      })
    );
    return Object.fromEntries(entries);
  },
  drugCategoryData: (state, getters) => index => {
    let e = getters.drugCategoriesDict[index] || {
      index: index,
      en: "",
      es: "",
      po: "",
      order: "",
      drugs: []
    };
    e.level = index.split(".").length;
    e.parent =
      e.level == 1
        ? ""
        : index
            .split(".")
            .slice(0, -1)
            .join(".");
    return e;
  },
  drugCategoryParents: (state, getters) => activeindex => {
    return getters.drugCategories
      .filter(e => {
        return activeindex == e.index || activeindex.startsWith(e.index + ".");
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  },
  drugCategoriesByParent: (state, getters) => parent => {
    return getters.drugCategories
      .filter(e => {
        return e.parent == parent;
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }
};

const mutations = {
  SET_DRUG_CATEGORIES(state, drugCategories) {
    state.drugCategories = drugCategories.map(e => {
      e.level = e.index.split(".").length;
      e.parent =
        e.level == 1
          ? ""
          : e.index
              .split(".")
              .slice(0, -1)
              .join(".");
      return e;
    });
  }
};

const actions = {
  async getDrugCategories({ commit }) {
    commit("SET_DRUG_CATEGORIES", []);
    return Api.get("/all-categories-data")
      .then(resp => {
        commit("SET_DRUG_CATEGORIES", resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
