<template>
  <div v-if="authLoading" id="loading-wrapper">
    <img
      id="loading-text"
      src="@/assets/logo/Logo-Verde.png"
      style="width: 168px;"
    />
    <div id="loading-content"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SpashScreen",
  computed: {
    ...mapGetters({
      authLoading: "auth/authLoading"
    })
  }
};
</script>

<style scoped>
#loading-wrapper {
  background: #1c093a;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #000000;
  margin: -14px 0 0 -84px;
  text-align: center;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 270px;
  height: 270px;
  margin: -135px 0 0 -135px;
  border: 3px solid #64e2c4;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #64e2c4;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #64e2c4;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #64e2c4;
  border-bottom-color: #64e2c4;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #64e2c4;
  border-bottom-color: #64e2c4;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #64e2c4;
  border-bottom-color: #64e2c4;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#content-wrapper {
  color: #fff;
  position: fixed;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    visibility: hidden;
  }
}
</style>
