const state = () => ({
  pgxDrugResults: []
});

const getters = {
  pgxDrugResults: state => {
    return state.pgxDrugResults;
  },
  pgxDrugResultsDict: state => {
    const entries = new Map(
      state.pgxDrugResults.map(e => {
        return [
          e.drug,
          {
            missing_n_genes: e.missing_n_genes,
            result: e.result,
            source: e.source
          }
        ];
      })
    );
    return Object.fromEntries(entries);
  },
  pgxGreenDrugsSlugs: state => {
    const greenDrugs = state.pgxDrugResults
      .filter(e => {
        return e.result >= 0.25 && e.result < 0.5;
      })
      .map(d => d.drug);
    return greenDrugs;
  },
  pgxDrugResult: (state, getters) => drugSlug => {
    return (
      getters.pgxDrugResultsDict[drugSlug] || {
        missing_n_genes: 0,
        result: -2,
        source: {}
      }
    );
  }
};

const mutations = {
  UNSET_PGX_DRUG_RESULTS(state) {
    state.pgxDrugResults = [];
  },
  SET_PGX_DRUG_RESULTS(state, pgxDrugResults) {
    state.pgxDrugResults = pgxDrugResults;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
