import { Magic } from "magic-sdk";
import Vue from "vue";
import Api from "@/services/Strapi.js";
import router from "@/router";
const m = new Magic(process.env.VUE_APP_MAGIC_KEY, {
  locale: "es",
  network: "mainnet"
});

const state = () => ({
  user: null,
  strapiUserData: {
    type: "guest"
  },
  ethAddress: null,
  authLoading: false
});

const getters = {
  role: state => {
    return (state.strapiUserData && state.strapiUserData.type) || "guest";
  },
  authToken: state => {
    return state.user.idToken;
  },
  userId: state => {
    return (state.strapiUserData && state.strapiUserData.id) || "guest";
  },
  initials: state => {
    return (state.strapiUserData && state.strapiUserData.initials) || "GU";
  },
  authLoading: state => {
    return state.authLoading;
  },
  strapiUserData: state => {
    return state.strapiUserData;
  }
};

const actions = {
  async login({ commit, dispatch }, email) {
    commit("SET_AUTH_LOADING", true);

    await m.auth.loginWithEmailOTP({
      ...email,
      showUI: true
    });

    const data = await m.user.getMetadata();
    const idToken = await m.user.getIdToken({ lifespan: 900000000000 });
    commit("SET_USER_DATA", { data, idToken });
    return dispatch("getMyUserData");
  },

  getMyUserData() {
    return Api.get("/users/me");
  },
  refreshUserData({ commit }) {
    return Api.get("/users/me").then(resp => {
      commit("SET_STRAPI_USER_DATA", resp.data);
    });
  },
  async updateMe({ commit }, payload) {
    return Api.put(`/updateMe`, payload).then(res => {
      commit("SET_STRAPI_USER_DATA", res.data);
    });
  },
  async logout({ commit }) {
    commit("SET_AUTH_LOADING", true);
    commit("CLEAR_USER_DATA");
    commit("gxSample/UNSET_SAMPLE", false, { root: true });
    commit("pgxDrugResults/UNSET_PGX_DRUG_RESULTS", false, { root: true });
    commit("gxResults/UNSET_GX_RESULTS", false, { root: true });
    commit("gxResults/UNSET_GX_RESULTS", false, { root: true });
    commit("gxGeneResults/UNSET_GX_GENE_RESULTS", false, { root: true });
    commit("pgxGenes/UNSET_PGX_DATA", false, { root: true });
    commit("app/SET_ALL_DATA_LOADED", false, { root: true });
    await m.user.logout();
    commit("SET_AUTH_LOADING", false);
  }
};

// mutations
const mutations = {
  SET_AUTH_LOADING(state, authLoadingState) {
    state.authLoading = authLoadingState;
  },

  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem("user", JSON.stringify(userData));
  },
  SET_STRAPI_USER_DATA(state, strapiUserData) {
    strapiUserData.type = strapiUserData.type || "basic";
    state.strapiUserData = strapiUserData;
    localStorage.setItem(
      "userRole",
      JSON.stringify({ role: strapiUserData.type })
    );
  },
  CLEAR_USER_DATA(state) {
    state.user = null;
    state.strapiUserData = {
      type: "guest"
    };
    Vue.prototype.$user.set({ role: "guest" });
    localStorage.setItem("userRole", JSON.stringify({ role: "guest" }));
    router.push({ name: "Login" });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
